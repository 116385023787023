import React from 'react';
import Slider from "react-slick";
import '../Hero/hero.css';
import { NavLink } from "react-router-dom";
import { useGlobalContext } from '../../providers/GlobalProvider';
import styled from 'styled-components'

const Hero1 = () => {

    const { layout } = useGlobalContext();

    /**
     * slider settings with parameters
     * @type {{fade: boolean, dots: boolean, infinite: boolean, slidesToScroll: number, focusOnSelect: boolean, slidesToShow: number, lazyLoad: boolean, speed: number, autoplay: boolean}}
     */
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        focusOnSelect: false,
        fade: true,
        lazyLoad: true
    };


    return (
        <>
            <section className="hero-1">
                <Slider {...settings}>
                    {
                        layout.hero_1.hero_data.map((item, index) => (
                            <div key={index} className="swiper-slide">
                                <div className="slide-inner slide-bg-image"
                                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL + item.img})` }}>
                                    <div className="container-1410">
                                        <div className="slide-text">
                                            <p>{item.text}</p>
                                        </div>
                                        <div className="slide-title">
                                            <h2>{item.title}</h2>
                                        </div>
                                        <div className="clearfix" />
                                        <div className="slide-btns">
                                            <ButtonWrapper>
                                                <NavLink to={item.link} className="theme-btn-s7">{layout.hero_1.shop_now_button_label}</NavLink>
                                            </ButtonWrapper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </section>
        </>
    )
}

export default Hero1

const ButtonWrapper = styled.div`
    position: relative;
    z-index: 10;
`