import React from 'react'

const OrderingToolbar = ({ HandleOrderingStatus, ordering }) => {
    return (
        <>
            <div className="products-sizes">
                <a href="/" onClick={(event) => {
                    HandleOrderingStatus(event, 1)
                }} className={"grid-4 " + (ordering === 1 ? 'active' : '')}>
                    <div className="grid-draw">
                        <span /><span /><span /><span />
                    </div>
                    <div className="grid-draw">
                        <span /><span /><span /><span />
                    </div>
                    <div className="grid-draw">
                        <span /><span /><span /><span />
                    </div>
                </a>
                <a href="/" onClick={(event) => {
                    HandleOrderingStatus(event, 2)
                }} className={"grid-3 " + (ordering === 2 ? 'active' : '')}>
                    <div className="grid-draw">
                        <span /><span /><span />
                    </div>
                    <div className="grid-draw">
                        <span /><span /><span />
                    </div>
                    <div className="grid-draw">
                        <span /><span /><span />
                    </div>
                </a>
                <a href="/" onClick={(event) => {
                    HandleOrderingStatus(event, 3)
                }} className={"list-view " + (ordering === 3 ? 'active' : '')}>
                    <div className="grid-draw-line">
                        <span /><span />
                    </div>
                    <div className="grid-draw-line">
                        <span /><span />
                    </div>
                    <div className="grid-draw-line">
                        <span /><span />
                    </div>
                </a>
            </div>
        </>
    )
}

export default OrderingToolbar