import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from '../header/Header'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useGlobalContext } from '../../providers/GlobalProvider'

const Layout = ({ children }) => {

    const { navbarHeight } = useGlobalContext()

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    return (
        <>
            <Header />
            <ChildrenWrapper navbarHeight={navbarHeight}>
                <main>{children}</main>
            </ChildrenWrapper>
            <Footer />
        </>
    )
}

export default Layout

const ChildrenWrapper = styled.div`
    margin-top: ${props => props.navbarHeight}px;
`