import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useOrderContext } from '../../providers/OrderProvider';


const ShippingFields = () => {

    const { layout } = useGlobalContext()
    const {
        name,
        setName,
        phone,
        setPhone,
        address,
        setAddress
    } = useOrderContext()


    return <>
        <div className="col-2">
            <div className="woocommerce-shipping-fields">
                <div className="shipping_address">
                    <p className="form-row form-row form-row-first validate-required"
                        id="shipping_first_name_field">
                        <label htmlFor="shipping_first_name">{layout.checkout.shipping_fields.first_name_label} <abbr className="required"
                            title="required">*</abbr>
                        </label>
                        <input type="text" className="input-text " placeholder="" value={name}
                            onChange={(e) => { setName(e.target.value) }} />
                    </p>
                    {/* <p className="form-row form-row form-row-last validate-required"
                        id="shipping_last_name_field">
                        <label htmlFor="shipping_last_name">{layout.checkout.shipping_fields.last_name_label} <abbr className="required"
                            title="required">*</abbr>
                        </label>
                        <input type="text" className="input-text" placeholder="" value={lastName}
                            onChange={(e) => { setLastName(e.target.value) }} />
                    </p>
                    <p className="form-row form-row form-row-first validate-required validate-email"
                        id="billing_email_field">
                        <label htmlFor="shipping_email">{layout.checkout.shipping_fields.email_label} <abbr className="required"
                            title="required">*</abbr></label>
                        <input type="email" className="input-text" placeholder="" value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </p> */}
                    <p className="form-row form-row form-row-last validate-required validate-phone"
                        id="shipping_phone_field">
                        <label htmlFor="billing_phone">{layout.checkout.shipping_fields.phone_label} <abbr className="required"
                            title="required">*</abbr></label>
                        <input type="tel" className="input-text" placeholder="" value={phone}
                            onChange={(e) => {
                                const removedText = e.target.value.toString().replace(/[^0-9]+/, '');
                                setPhone(removedText)
                            }}
                        />
                    </p>

                    <p className="form-row form-row form-row-wide address-field validate-required"
                        id="shipping_address_1_field">
                        <label htmlFor="shipping_address_1">{layout.checkout.shipping_fields.address_label} <abbr className="required"
                            title="required">*</abbr></label>
                        <input type="text" className="input-text " placeholder="" value={address}
                            onChange={(e) => { setAddress(e.target.value) }}
                        />
                    </p>
                </div>

                {/* <p className="form-row form-row notes" id="order_comments_field">
                    <label htmlFor="order_comments">{layout.checkout.shipping_fields.notes_label}</label>
                    <textarea className="input-text" placeholder={layout.checkout.shipping_fields.notes_placeholder}
                        rows={2} cols={5} value={notes}
                        onChange={(e) => { setNotes(e.target.value) }}
                    />
                </p> */}
            </div>
        </div>
    </>;
};

export default ShippingFields;