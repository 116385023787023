import React from 'react';
import { NavLink } from 'react-router-dom';
import Layout from '../../components/global/Layout';
import './paymentMethods.css';
import { useGlobalContext } from '../../providers/GlobalProvider';

const PaymentMethods = () => {

    const { layout } = useGlobalContext();

    return (
        <Layout>
            {/* start error-404-section */}
            <section className="error-404-section section-padding">
                <div className="container-1410">
                    <div className="error-404-area">
                        {/* <h2>{layout.not_found.error_num}</h2> */}
                        <div>
                            <h3>{layout.payment_methods.title}</h3>
                            <p>{layout.payment_methods.description}</p>
                            <NavLink to="/" className="theme-btn">{layout.payment_methods.back_button_label}</NavLink>
                        </div>
                    </div>
                </div>
            </section>
            {/* end error-404-section */}
        </Layout>
    );
}

export default PaymentMethods