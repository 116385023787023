import React from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import { useGlobalContext } from '../../providers/GlobalProvider';

const Categories = () => {
    const { layout } = useGlobalContext();

    return (
        <>
            <section className="category-section-area section-padding category-section-margin">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="section-title-s3">
                                <h2>{layout.categories.title}</h2>
                                <p>{layout.categories.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col col-xs-12"> */}

                        <div className="all-cat">
                            <ul className="clearfix">
                                {
                                    layout.categories.categories_data.map((item, index) => (
                                        item.hidden === false ? (
                                            <li key={index}>
                                                <NavLink to={item.link}>
                                                    {/* <i className={"fi " + item.icon} /> */}
                                                    <CategoryImg width={70} src={process.env.PUBLIC_URL + item.icon} />
                                                    <span>{item.name}</span>
                                                </NavLink>
                                            </li>
                                        ) : <div key={index}></div>

                                    ))
                                }
                            </ul>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
                {/* end container-1410 */}
            </section>
        </>
    )
}

export default Categories


const CategoryImg = styled.img`
`
