import React from 'react';
import Slider from "react-slick";
import PageTitle from '../../components/global/PageTitle';
import './about.css';
import { NavLink } from "react-router-dom";
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';
import styled from 'styled-components';


const About = () => {
    const { layout } = useGlobalContext()

    /**
     * slider settings
     */
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        focusOnSelect: false
    };

    return (
        <Layout>

            <PageTitle name={layout.about_us.title} />

            {/* start about-section */}
            <section className="about-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-8">
                            <div className="about-area">
                                <div className="info">
                                    <span>{layout.about_us.left_side.top_title}</span>
                                    <h2>{layout.about_us.left_side.middle_title}</h2>
                                    <p>{layout.about_us.left_side.bottom_description}</p>
                                    <NavLink to="/contact" className="theme-btn-s3">{layout.about_us.left_side.contact_us_button_label}</NavLink>
                                </div>
                                <div className="img-holder">
                                    <img loading="lazy" src={process.env.PUBLIC_URL + "/assets/images/about.jpg"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-4">
                            <div className="mission-vision-area">
                                <div>
                                    <h3>{layout.about_us.right_side.mission_title}</h3>
                                    <p>{layout.about_us.right_side.mission_description}</p>
                                </div>
                                <div>
                                    <h3>{layout.about_us.right_side.goals_title}</h3>
                                    <p>{layout.about_us.right_side.goals_description}</p>
                                </div>
                                {/* <NavLink to="/shop-full-width" className="theme-btn">Go to shop</NavLink> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end about-section */}

            {/* start testimonials-section */}
            <section className="testimonials-section">
                <ContentWrapper>


                    <div className="container-1410">
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="section-title-s4">
                                    <h2>{layout.about_us.testimonials.title}</h2>
                                    <p>{layout.about_us.testimonials.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6">
                                <div className="testimonial-grids testimonials-slider">
                                    <Slider {...settings}>
                                        {
                                            layout.about_us.testimonials.items.map((item, index) => (
                                                <div key={index} className="grid">
                                                    <p>“{item.text}”</p>
                                                    <div className="client-info">
                                                        <h5>{item.name}</h5>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </section>
            {/* end testimonials-section */}

        </Layout>
    )
}

export default About

const ContentWrapper = styled.div`
    position: relative;
    z-index: 10;
`