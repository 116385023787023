import React, { Fragment } from 'react';
import Slider from "react-slick";
import '../Hero/hero.css';
import { NavLink } from "react-router-dom";
import { useGlobalContext } from '../../providers/GlobalProvider';


const Hero2 = () => {

    const { layout } = useGlobalContext();


    /**
         * slider settings with parameters
         * @type {{fade: boolean, dots: boolean, infinite: boolean, slidesToScroll: number, focusOnSelect: boolean, slidesToShow: number, lazyLoad: boolean, speed: number, autoplay: boolean}}
         */
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        focusOnSelect: false,
        fade: true,
        lazyLoad: false
    };

    return (
        <>
            <section className="hero-2">
                <Slider {...settings}>
                    {
                        layout.hero_2.hero_data.map((item, index) => (
                            <div key={index} className="swiper-slide">
                                <div className="slide-inner slide-bg-image"
                                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL + item.img})` }}>
                                    <div className="container-1410">
                                        <div className="row">
                                            <div className="col col-xs-12">
                                                <div className="inner">
                                                    <div className="sell-off">
                                                        <p>{layout.hero_2.sell_off_label}</p>
                                                    </div>
                                                    <div className="slide-title">
                                                        <h2>{layout.hero_2.slide_title}</h2>
                                                    </div>
                                                    <div className="clearfix" />
                                                    <div className="slide-btns">
                                                        <NavLink to="/shop-full-width" className="theme-btn-s7 shop-new">{layout.hero_2.shop_now_button_label}</NavLink>
                                                        <NavLink to="/shop-full-width" className="theme-btn-s7">{layout.hero_2.find_more_button_label}</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </section>
        </>
    )
}

export default Hero2

