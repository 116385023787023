import React from 'react';
import styled from 'styled-components';
// import {NavLink} from 'react-router-dom';


const PageTitle = ({ name, backgroundImg = "../assets/images/cta.jpg" }) => {
    return <>
        <TitleBgContainer style={{ background: "url(" + backgroundImg + ") center center/cover no-repeat local" }} >
            <ContentWrapper>
                <TitleH2>{name}</TitleH2>
                {/* <ol className="breadcrumb">
                    <li>
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li>{name}</li>
                </ol> */}
            </ContentWrapper>
        </TitleBgContainer>
    </>;
};

export default PageTitle;

const TitleBgContainer = styled.section`
    
    width: 100%;
    height: 30vh;
    display: flex;
    position: relative;
    z-index: 10;

    &:before{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 15;
        background: rgba(0, 0, 0, 0.2) 100%;
    }

    @media (max-width: 991px) {
        /* height: 20vh; */
    }
`;

const ContentWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
`;

const TitleH2 = styled.h2`
    color: white;
    text-shadow: 2px 2px 4px #000000;
    user-select: none;
`