import React from 'react';

const DiscountBadge = ({ item }) => {

    return (
        <>
            {parseInt(item.price) < parseInt(item.oldPrice) ?
                <div onClick={() => {
                    console.log(parseInt(item.price) / parseInt(item.oldPrice))
                }} className="product-badge discount">
                    {
                        (100 - Math.round((parseInt(item.price) / parseInt(item.oldPrice)) * 100))
                    }
                    %</div> : ''
            }
        </>
    );
};

export default DiscountBadge;
