import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';


const ContactWidget = () => {
    const { layout } = useGlobalContext()

    return (
        <>
            <div className="widget contact-widget">
                <h3>{layout.footer.contact_info.title}</h3>
                <ul>
                    <li>{layout.footer.contact_info.phone}</li>
                    <li>{layout.footer.contact_info.email}</li>
                    <li>{layout.footer.contact_info.address}</li>
                </ul>
            </div>
        </>
    )
}

export default ContactWidget
