import React from 'react';
import { NavLink } from 'react-router-dom';
import Layout from '../../components/global/Layout';
import './thankYouPage.css';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useOrderContext } from '../../providers/OrderProvider';
import Preloader from '../../components/global/Preloader';

const ThankYouPage = () => {

    const { layout } = useGlobalContext();
    const { orderId, isOrdering } = useOrderContext()

    return (
        <Layout>
            {/* start error-404-section */}
            {/* <section className="error-404-pg error-404-section section-padding"> */}
            <section className="error-404-section section-padding">
                <div className="container-1410">
                    <div className="error-404-area">
                        <div className="error-message">
                            {isOrdering ?
                                (
                                    <><Preloader /></>
                                ) : (
                                    <>
                                        <h3>{layout.thank_you_page.title}</h3>
                                        <p>{layout.thank_you_page.description}</p>
                                        {orderId > 0 ? (<h3>{layout.thank_you_page.order_number_label.replace('{0}', orderId.toString().padStart(6, '0'))}</h3>) : (<></>)}
                                        <NavLink to="/" className="theme-btn">{layout.thank_you_page.back_button_label}</NavLink>
                                    </>
                                )
                            }

                        </div>
                    </div>
                </div>
            </section >
            {/* end error-404-section */}
        </Layout >
    );
}

export default ThankYouPage