import React from 'react';

import Hero1 from '../../components/Hero/Hero1';
import Categories from '../../components/categories/Categories';
import CtaSection from '../../components/CTA/CtaSection';
import RecentProducts from '../../components/products/RecentProducts';
import BestSeller from '../../components/products/BestSeller';
import QuickView from '../../components/products/QuickView';
import Feature1 from '../../components/features/Feature1';
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';


const Default = () => {
    const { showQuickView, quickViewData } = useGlobalContext();

    return (
        <Layout>
            {showQuickView
                ? <QuickView
                    data={quickViewData}
                />
                : ''
            }


            <Hero1 />
            <Feature1 extraClass='' />
            {/* <FeaturedProducts /> */}
            <br />
            <br />
            <Categories />
            <RecentProducts />
            <CtaSection />
            <BestSeller />
        </Layout>
    )
}

export default Default

