import React, { useEffect, useRef, useState } from 'react';

import PageTitle from '../../components/global/PageTitle';
import Ordering from '../../components/shop/Ordering';
import QuickView from '../../components/products/QuickView';
import Pagination from "../../components/global/Pagination";
import OrderingToolbar from "../../components/shop/OrderingToolbar";
import Products from "../../components/shop/Products";

import './shop.css';


import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useProductsContext } from '../../providers/ProductsProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useOnScreen from '../../utils/CommonUtils';
// import OrderByPhoneProvider from '../../providers/OrderByPhoneProvider';
// import OrderByPhone from '../../components/global/OrderByPhone';

const FullWidth = () => {
    const [ordering, setOrdering] = useState(1);
    const [title, setTitle] = useState('');
    const [page, setPage] = useState(1);


    const { showQuickView, quickViewData, handelQuickViewClose, layout } = useGlobalContext()
    const {
        sex,
        getTotalPagesCount,
        isHasPagination,
        categoryProducts,
        getCategoryProducts,
        getCategoryProductsWithAppend,
        getCategoryNameById,
        currentCatergoryData,
        setCurrentCatergoryData
    } = useProductsContext()

    const navigate = useNavigate()
    const loadNewDivRef = useRef()
    const isOnScreen = useOnScreen(loadNewDivRef)

    const search = useLocation().search;
    const id = parseInt(new URLSearchParams(search).get("id"));

    const onPaginationValueChange = (value) => {
        setPage(value)
        getCategoryProducts(id, value - 1)
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        if (id === null || isNaN(id)) {
            navigate('/404', { replace: true })
        } else {
            if (id === 0) {
                setTitle(layout.category.title)
            } else {
                const catName = getCategoryNameById(id)
                if (catName === "") {
                    navigate('/404', { replace: true })
                } else {
                    setTitle(catName)
                    setCurrentCatergoryData(layout.categories.categories_data.find(i => i.id === id))
                }
            }

            setPage(1);
            getCategoryProducts(id, 0, parseInt(sex.value));
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        setPage(1);
        getCategoryProducts(id, 0, parseInt(sex.value))
        // eslint-disable-next-line
    }, [sex])


    useEffect(() => {
        if (categoryProducts.length && isOnScreen) {
            const totalPages = getTotalPagesCount();

            if (page + 1 <= totalPages) {
                setPage(page + 1)
            }
        }
        // eslint-disable-next-line
    }, [isOnScreen])

    useEffect(() => {
        if (categoryProducts.length && isOnScreen) {
            getCategoryProductsWithAppend(id, page - 1, parseInt(sex.value))
        }
        // eslint-disable-next-line
    }, [page])


    /**
     * Handle Ordering Status
     */
    const HandleOrderingStatus = (event, data) => {
        event.preventDefault();
        setOrdering(data);
    };


    return (
        <Layout>
            {showQuickView
                ? <QuickView
                    data={quickViewData}
                    onQuickViewCloseClick={handelQuickViewClose}
                />
                : ''
            }

            {
                currentCatergoryData === null ? <></> : <PageTitle name={title} backgroundImg={currentCatergoryData.cover} />
            }

            {/* start shop-section */}
            <section className="shop-section shop-style-2 section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="shop-area clearfix">
                                <div className="woocommerce-content-wrap">
                                    <div className="woocommerce-content-inner">
                                        <div className="woocommerce-toolbar-top">
                                            <p className="woocommerce-result-count">
                                                {/* Showing all {products.length} results */}
                                                {layout.category.show_result_label.replace('{0}', categoryProducts.length)}
                                            </p>

                                            <OrderingToolbar
                                                HandleOrderingStatus={HandleOrderingStatus}
                                                ordering={ordering}
                                            />

                                            {
                                                (currentCatergoryData !== null) ? (
                                                    currentCatergoryData.has_gender_filter === true ? <Ordering /> : <></>
                                                ) : (<></>)
                                            }

                                        </div>
                                        <Products
                                            products={categoryProducts}
                                            ordering={ordering}
                                        />

                                    </div>

                                    <div ref={loadNewDivRef} />
                                    <Paginatorwrapper>
                                        {
                                            isHasPagination() ? (
                                                <Pagination page={page} count={getTotalPagesCount()} onChange={onPaginationValueChange} />
                                            ) : (<></>)
                                        }

                                    </Paginatorwrapper>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container */}
            </section>
            {/* end shop-section */}

            {/* <OrderByPhoneProvider>
                <OrderByPhone />
            </OrderByPhoneProvider> */}
        </Layout >
    );
}

export default FullWidth


const Paginatorwrapper = styled.div`
    /* display: flex; */
    display: none;
    justify-content: center;
`