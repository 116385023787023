import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';

const NewsletterWidget = () => {
    const { layout } = useGlobalContext()

    return (
        <>
            <div className="widget newsletter-widget">
                <div className="inner">
                    <h3>{layout.footer.newslatter.title}</h3>
                    <p>{layout.footer.newslatter.description}</p>
                    <form>
                        <div className="input-1">
                            <input type="email" className="form-control" placeholder={layout.footer.newslatter.placeholder}
                                required />
                        </div>
                        <div className="submit clearfix">
                            <button type="submit">{layout.footer.newslatter.button_label}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewsletterWidget