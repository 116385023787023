import React, { useEffect } from 'react';
import Slider from "react-slick";
import ReactTooltip from 'react-tooltip';

import '../products/products.css';

import { Link } from "react-router-dom";
import ShopAction from './ShopAction';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useProductsContext } from '../../providers/ProductsProvider';
import { PUBLIC_CDN_URL } from '../../defs';
import ProductPriceAmount from './ProductPriceAmount';
import DiscountBadge from './DiscountBadge';


const RecentSingleProducts = ({ categoryId }) => {

    const { layout } = useGlobalContext()
    const { categoryProducts, getCategoryProducts } = useProductsContext()

    useEffect(() => {
        getCategoryProducts(categoryId)
        // eslint-disable-next-line
    }, []);


    /**
    * slider settings
    * @type {{swipeToSlide: boolean, dots: boolean, infinite: boolean, responsive: *[], slidesToScroll: number, focusOnSelect: boolean, slidesToShow: number, autoplay: boolean, speed: number, autoplaySpeed: number}}
    */
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        swipeToSlide: true,
        autoplaySpeed: 5000,
        focusOnSelect: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className="realted-porduct">
                <h3>{layout.related_products.title}</h3>
                <ul className="products product-row-slider">
                    <Slider {...settings}>
                        {
                            categoryProducts.map((item, index) => (
                                <li key={index} className="product">
                                    <div className="product-holder">
                                        <DiscountBadge item={item} />
                                        <Link to={"/product?id=" + item.id}>
                                            <img loading="lazy" src={PUBLIC_CDN_URL + item.mainImg} alt="" />
                                        </Link>
                                        <div className="shop-action-wrap">
                                            <ShopAction item={item} />
                                        </div>
                                    </div>
                                    <div className="product-info">
                                        <h4>
                                            <Link to={"/product?id=" + item.id}>
                                                {item.title}
                                            </Link>
                                        </h4>
                                        <ProductPriceAmount item={item} />
                                    </div>
                                </li>
                            ))
                        }
                    </Slider>
                </ul>
            </div>
            <ReactTooltip className='tool-tip' effect='solid' />
        </>
    );
};

export default RecentSingleProducts;