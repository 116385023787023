import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';


const AddReview = () => {

    const { layout } = useGlobalContext()


    return <>
        <div className="review-form">
            <h4>{layout.common.add_review.title}</h4>
            <form>
                <div>
                    <input type="text" className="form-control" placeholder={layout.common.add_review.name_placeholder} required />
                </div>
                <div>
                    <input type="email" className="form-control" placeholder={layout.common.add_review.email_placeholder} required />
                </div>
                <div>
                    <textarea className="form-control" placeholder={layout.common.add_review.review_placeholder} defaultValue={""} />
                </div>
                <div className="rating-wrapper">
                    <div className="rating">
                        <a href="/" className="star-1">
                            <i className="ti-star" />
                        </a>
                        <a href="/" className="star-1">
                            <i className="ti-star" />
                        </a>
                        <a href="/" className="star-1">
                            <i className="ti-star" />
                        </a>
                        <a href="/" className="star-1">
                            <i className="ti-star" />
                        </a>
                        <a href="/" className="star-1">
                            <i className="ti-star" />
                        </a>
                    </div>
                    <div className="submit">
                        <button type="submit" className="theme-btn">{layout.common.add_review.submit_button_label}</button>
                    </div>
                </div>
            </form>
        </div>
    </>;
};

export default AddReview;