import React, { createContext, useContext, useEffect, useState } from 'react'
import { useApiContext } from './ApiProvider';

const GlobalContext = createContext()

const GlobalProvider = ({ children }) => {
    const [showQuickView, setShowQuickView] = useState(false);
    const [quickViewData, setQuickViewData] = useState({});
    const [navbarHeight, setNavbarHeight] = useState({});

    const [showMiniCart, setShowMiniCart] = useState(false);
    const [showSideInfo, setShowSideInfo] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [showOrderByPhone, setShowOrderByPhone] = useState(false);
    const [layout, setLayout] = useState({});
    const [layouLoaded, setLayoutLoaded] = useState(false);

    const { getLayoutRequest } = useApiContext()

    useEffect(() => {
        getLayoutRequest(
            (data) => {
                setLayout(data)
                setLayoutLoaded(true)
            },
            (error) => {
                console.log(error)
            })
    }, [getLayoutRequest])

    const handleOrderByPhone = () => {
        setShowOrderByPhone(!showOrderByPhone)
    };


    const handelMiniCartStatus = () => {
        setShowMiniCart(!showMiniCart);
    };

    const handelMobileNavStatus = () => {
        setShowMobileNav(!showMobileNav);
    };

    const handelSideInfoStatus = () => {
        setShowSideInfo(!showSideInfo);
    };

    const handleOverlayStatus = () => {
        setShowMiniCart(false);
        setShowSideInfo(false);
        setShowMobileNav(false);
        setShowOrderByPhone(false)
    }

    const handelQuickViewData = (e, item) => {
        e.preventDefault();
        setShowQuickView(!showQuickView);
        setQuickViewData(item);
    };


    const handelQuickViewClose = (e) => {
        e.preventDefault();
        setShowQuickView(false);
        setQuickViewData({});
    };

    const formattedPriceWithCurrency = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + layout.common.currency_symbol;
    }

    const contextValue = {
        layout,
        layouLoaded,
        showMiniCart,
        showSideInfo,
        showMobileNav,
        showQuickView,
        showOrderByPhone,
        quickViewData,
        navbarHeight,

        setNavbarHeight,
        formattedPriceWithCurrency,
        handelQuickViewData,
        handelQuickViewClose,
        handelMiniCartStatus,
        handelMobileNavStatus,
        handelSideInfoStatus,
        handleOverlayStatus,
        handleOrderByPhone
    }

    return (
        <GlobalContext.Provider value={contextValue}>
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobalContext = () => useContext(GlobalContext)

export default GlobalProvider
