import React, { Fragment } from 'react';
import Slider from "react-slick";
import ReactTooltip from 'react-tooltip';
import ShopAction from "./ShopAction";

import '../products/products.css';

import { Link } from "react-router-dom";
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useProductsContext } from '../../providers/ProductsProvider';
import { PUBLIC_CDN_URL } from '../../defs';
import ProductPriceAmount from './ProductPriceAmount';
import DiscountBadge from './DiscountBadge';

const BestSeller = () => {

    const { layout } = useGlobalContext()
    const { products } = useProductsContext()


    /**
         * slider settings
         */
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        swipeToSlide: true,
        autoplaySpeed: 5000,
        focusOnSelect: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            {/* start trendy-product-section */}
            <section className="best-seller-section section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="section-title-s2">
                                <h2>{layout.bestsellers.title}</h2>
                            </div>
                            <a href="/" className="more-products">{layout.bestsellers.more_products_button_label}</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="products-wrapper">
                                <ul className="products product-row-slider">
                                    <Slider {...settings}>
                                        {
                                            products.map((item, index) => (
                                                <li key={index} className="product">
                                                    <div className="product-holder">
                                                        <DiscountBadge item={item} />
                                                        <Link to={"/product?id=" + item.id}>
                                                            <img loading="lazy" src={PUBLIC_CDN_URL + item.mainImg} alt="" />
                                                        </Link>

                                                        <div className="shop-action-wrap">
                                                            <ShopAction item={item} />
                                                        </div>
                                                    </div>
                                                    <div className="product-info">
                                                        <h4>
                                                            <Link to={"/product?id=" + item.id}>
                                                                {item.title}
                                                            </Link></h4>
                                                        <ProductPriceAmount item={item} />
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </Slider>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container-1410 */}
            </section>
            {/* end trendy-product-section */}
            <ReactTooltip className='tool-tip' effect='solid' />
        </>
    )
}

export default BestSeller