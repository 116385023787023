import React from 'react';

import Hero2 from '../../components/Hero/Hero2';
import QuickView from '../../components/products/QuickView';
import Feature1 from '../../components/features/Feature1';
import OurProducts from '../../components/products/OurProducts';
import Deal from '../../components/features/Deal';
import Partners from '../../components/features/Partners';
import FromOurBlog from '../../components/blog/FromOurBlog';
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';

const Style2 = () => {
    const { showQuickView, quickViewData, handelQuickViewClose } = useGlobalContext();

    return (
        <Layout>
            {showQuickView
                ? <QuickView
                    data={quickViewData}
                    onQuickViewCloseClick={handelQuickViewClose}
                />
                : ''
            }

            <Hero2 />
            <Feature1 extraClass='' />
            <OurProducts />
            <Deal />
            <Partners />
            <FromOurBlog />
        </Layout>
    );
}

export default Style2