import React from 'react';

import PageTitle from '../../components/global/PageTitle';
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';


const ContactUs = () => {
    const { layout } = useGlobalContext()

    const onSubmitForm = (e) => {
        e.preventDefault();
    };

    return (
        <Layout>
            <PageTitle name={layout.contact.title} />

            {/* start contact-section */}
            <section className="contact-section contact-pg-section section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-lg-10 col-lg-offset-1">
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="pe-7s-culture" />
                                        <h4>{layout.contact.address_label}</h4>
                                        <p>{layout.contact.address_value}</p>
                                    </li>
                                    <li>
                                        <i className="pe-7s-phone" />
                                        <h4>{layout.contact.phone_label}</h4>
                                        <p>{layout.contact.phone_value}</p>
                                    </li>
                                    <li>
                                        <i className="pe-7s-mail" />
                                        <h4>{layout.contact.email_label}</h4>
                                        <p>{layout.contact.email_value} </p>
                                    </li>
                                    <li>
                                        <i className="pe-7s-alarm" />
                                        <h4>{layout.contact.time_label}</h4>
                                        <p>{layout.contact.time_value}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="contact-form-col">
                                <h2>{layout.contact.contact_from.title}</h2>
                                <div className="contact-form">
                                    <form method="post" className="contact-validation-active" id="contact-form-main">
                                        <div>
                                            <input type="text" name="name" id="name" placeholder={layout.contact.contact_from.name_placeholder} />
                                        </div>
                                        <div>
                                            <input type="email" name="email" id="email" placeholder={layout.contact.contact_from.email_placeholder} />
                                        </div>
                                        {/* <div className="fullwidth">
                                            <select name="subject">
                                                <option disabled="disabled">Contact subject</option>
                                                <option>Subject 1</option>
                                                <option>Subject 2</option>
                                                <option>Subject 3</option>
                                            </select>
                                        </div> */}
                                        <div className="fullwidth">
                                            <textarea name="note" id="note" placeholder={layout.contact.contact_from.description_placeholder} defaultValue={""} />
                                        </div>
                                        <div className="submit-area">
                                            <button onClick={onSubmitForm} type="submit" className="theme-btn">{layout.contact.contact_from.submit_button_label}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end contact-section-s3 */}

            {/*  start contact-map */}
            <section className="contact-map-section">
                <h2 className="hidden">{layout.contact.map_title}</h2>
                <div className="contact-map">
                    <iframe title="map"
                        src={layout.contact.map_url}
                        allowFullScreen />
                </div>
            </section>
            {/* end contact-map */}

        </Layout>
    );
}

export default ContactUs