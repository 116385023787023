import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';

const Coupon = () => {
    const { layout } = useGlobalContext();


    const onClickSubmit = (e) => {
        e.preventDefault();
    };

    return <>
        <tr>
            <td colSpan={6} className="actions">
                <div className="coupon">
                    <input type="text" name="coupon_code" className="input-text"
                        id="coupon_code" placeholder={layout.cart.coupon.coupon_placholder} />
                    <input onClick={onClickSubmit} type="submit" value={layout.cart.coupon.send_button_label}
                        className="button" name="apply_coupon" />
                </div>
            </td>
        </tr>
    </>;
};

export default Coupon;