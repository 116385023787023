import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';
import styled from 'styled-components'

const ProductPriceAmount = ({ item }) => {
    const { formattedPriceWithCurrency } = useGlobalContext()

    return (
        <span className="woocommerce-Price-amount amount">
            <PriceWrapper>
                <ins>
                    <span className="woocommerce-Price-amount amount">
                        <bdi style={{ color: (parseInt(item.price) < parseInt(item.oldPrice)) ? 'red' : 'black' }}>
                            <span className="woocommerce-Price-currencySymbol">{formattedPriceWithCurrency(item.price)}</span>

                        </bdi>
                    </span>
                </ins>
                {parseInt(item.price) < parseInt(item.oldPrice) ?
                    <del>
                        <span className="woocommerce-Price-amount amount">
                            <bdi>
                                <span className="woocommerce-Price-currencySymbol">{formattedPriceWithCurrency(item.oldPrice)}</span>
                            </bdi>
                        </span>
                    </del> : ''
                }
            </PriceWrapper>
        </span>
    );
};

export default ProductPriceAmount;

const PriceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`