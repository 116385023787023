import React from 'react';
import styled from 'styled-components';
import { useCartContext } from '../../providers/CartProvider';
import { useGlobalContext } from '../../providers/GlobalProvider';

const ShopAction = ({ item }) => {
    const { handelQuickViewData, layout } = useGlobalContext();
    const { addToCart } = useCartContext();

    const onClickAction = (e) => {
        e.preventDefault();
        addToCart(item);
    };

    return (
        <>
            <ul className="shop-action">
                <li>
                    <ActionButton href="/" data-tip={layout.common.quick_view_tooltip_label}
                        onClick={e => handelQuickViewData(e, item)}>
                        <i className="fi flaticon-view" />
                    </ActionButton>
                </li>
                {/* <li>
                    <ActionButton onClick={onClickAction} title={layout.common.add_to_wishlist_tooltip_label}
                        data-tip={layout.common.add_to_wishlist_tooltip_label}>
                        <i className="fi icon-heart-shape-outline" />
                    </ActionButton>
                </li> */}
                <li>
                    <ActionButton onClick={onClickAction} data-tip={layout.common.add_to_cart_tooltip_label}>
                        <i className="fi flaticon-shopping-cart" />
                    </ActionButton>
                </li>
            </ul>
        </>
    )
}

export default ShopAction


const ActionButton = styled.span`
    background-color: #fff;
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #000;
    display: inline-block;
    border-radius: 45px;
    box-shadow: 0px 3px 6.58px 0.42px rgba(0, 0, 0, 0.08);
    cursor: pointer;

    &:hover{
        background-color: #000;
        color: #fff;
    }
    &:active{
        background-color: #c2572b;
    }
    
`