import React from 'react';
import { NavLink } from 'react-router-dom';
import Layout from '../../components/global/Layout';
import './notFound.css';
import { useGlobalContext } from '../../providers/GlobalProvider';

const NotFound = () => {

    const { layout } = useGlobalContext();

    return (
        <Layout>
            {/* start error-404-section */}
            <section className="error-404-pg error-404-section section-padding">
                <div className="container-1410">
                    <div className="error-404-area">
                        <h2>{layout.not_found.error_num}</h2>
                        <div className="error-message">
                            <h3>{layout.not_found.title}</h3>
                            <p>{layout.not_found.description}</p>
                            <NavLink to="/" className="theme-btn">{layout.not_found.back_button_label}</NavLink>
                        </div>
                    </div>
                </div>
            </section>
            {/* end error-404-section */}
        </Layout>
    );
}

export default NotFound