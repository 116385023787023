import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useGlobalContext } from '../../providers/GlobalProvider';
import Logo from "./Logo";
import './navBar.css';

/**
 * nav bar component
 * @param options
 * @returns {*}
 * @constructor
 */
function Navbar() {
    const { handelMobileNavStatus, showMobileNav, layout } = useGlobalContext();

    return (
        <>
            {showMobileNav ?
                (
                    <div id="navbar" className={"navbar-collapse collapse navigation-holder " + (showMobileNav ? 'slideInn' : '')}>
                        <button onClick={handelMobileNavStatus} className="close-navbar"><i className="ti-close" /></button>
                        <ul className="nav navbar-nav">
                            {
                                layout.categories.categories_data.map((item, index) => (
                                    item.hidden === false ? (
                                        <li key={index}><NavLink onClick={handelMobileNavStatus} to={item.link}>{item.name}</NavLink></li>
                                    ) : (<></>)
                                ))
                            }
                        </ul>
                    </div>
                ) : (
                    <div id="navbar" className={"navbar-collapse collapse navigation-holder " + (showMobileNav ? 'slideInn' : '')}>
                        <button onClick={handelMobileNavStatus} className="close-navbar"><i className="ti-close" /></button>
                        <ul className="nav navbar-nav">
                            <li><NavLink to={layout.header.navbar.home.link} className={({ isActive }) => isActive ? "current-menu-item" : ""}>{layout.header.navbar.home.label}</NavLink></li>
                            <li><NavLink to={layout.header.navbar.about.link} className={({ isActive }) => isActive ? "current-menu-item" : ""}>{layout.header.navbar.about.label}</NavLink></li>

                            <Logo />
                            {/* <li><NavLink to={layout.header.navbar.shop.link} className={({ isActive }) => isActive ? "current-menu-item" : ""}>{layout.header.navbar.shop.label}</NavLink></li> */}
                            <li className="menu-item-has-children">
                                <ShopLink>{layout.header.navbar.shop.label}</ShopLink>
                                <ul className="sub-menu">
                                    {
                                        layout.categories.categories_data.map((item, index) => (
                                            item.hidden === false ? (
                                                <LinkItem key={index} to={item.link}>{item.name}</LinkItem>
                                            ) : (<></>)
                                        ))
                                    }
                                </ul>
                            </li>
                            <li><NavLink to={layout.header.navbar.contact.link} className={({ isActive }) => (isActive ? "current-menu-item" : "")}>{layout.header.navbar.contact.label}</NavLink></li>
                        </ul>
                    </div>
                )
            }

            {/* end of nav-collapse */}
        </>
    );
}

export default Navbar;

const ShopLink = styled.a`
    cursor: pointer;
`

const LinkItem = styled(NavLink)`
    &:hover{
        text-decoration: none;
        color: #c2572b !important;
    }
`