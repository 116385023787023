import React from 'react';
import { useProductsContext } from '../../providers/ProductsProvider';



const Ordering = () => {
    const { sexTypes, sex, setSex } = useProductsContext()

    const onSexTypeChange = (e) => {
        e.preventDefault();

        setSex(sexTypes[e.target.selectedIndex])
    }


    return <>
        <form>
            <select style={{ paddingRight: '50px' }} value={sex.value} onChange={onSexTypeChange}>
                {sexTypes.map((item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                ))}

            </select>
        </form>
    </>;
};

export default Ordering;