import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useGlobalContext } from '../../providers/GlobalProvider';

const CtaSection = () => {
    const { layout } = useGlobalContext();

    return <>
        {/* start cta-section */}
        <section className="cta-section">
            <ContentWrapper>
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="content-area">
                                <span>{layout.cta.top_title}</span>
                                <h3>{layout.cta.title}</h3>
                                <p>{layout.cta.description}</p>
                                <Link className="theme-btn-s2" to="/shop-full-width">
                                    {layout.cta.shop_now_button_label}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </section>
        {/* end cta-section */}
    </>;
};

export default CtaSection;

const ContentWrapper = styled.div`
    position: relative;
    z-index: 10;
`